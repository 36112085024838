/* Video wrapper */
.atomic-video {
  height: 100%;
}

.atomic-video > div {
  height: 100%;
}

.atomic-video .jwplayer.jw-flag-aspect-mode {
  height: 100% !important;
}

/* Hero video wrapper */
@media (min-width: 767px) {
  .hero-video-wrapper {
    height: calc(
      756px - var(--outside-header-height) - var(--site-header-height)
    );
  }
}

/* Hero video */
.hero-video {
  aspect-ratio: 16/9;
}

@media (min-width: 767px) {
  .hero-video {
    aspect-ratio: auto;
    height: 100%;
    pointer-events: auto;
  }
}

.hero-video .atomic-video {
  width: 100%;
}

.hero-video img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

/** JW Player overrides **/

/* Hide controls */
.preview-video .jw-slider-time,
.preview-video .jw-button-container .jw-icon-playback,
.preview-video .jw-button-container .jw-icon-rewind,
.preview-video .jw-button-container .jw-text-elapsed,
.preview-video .jw-button-container .jw-text-duration,
.preview-video .jw-button-container .jw-settings-sharing,
.preview-video .jw-button-container .jw-settings-submenu-button,
.preview-video .jw-button-container .jw-settings-pip,
.preview-video .jw-button-container .jw-icon-pip,
.preview-video .jw-button-container .jw-icon-fullscreen,
.preview-video .jw-icon-next,
.preview-video .jw-reset-text,
.preview-video .jw-icon.jw-icon-rewind.jw-button-color.jw-reset,
.preview-video .jw-icon.jw-icon-display.jw-button-color.jw-reset {
  display: none !important;
}

.atomic-video .jw-related-shelf-container {
  display: none !important;
}

/* force override default rewind on preview video  */
.preview-video .jw-display {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.preview-video .jwplayer.jw-state-playing.jw-flag-user-inactive .jw-display {
  display: block;
}

.preview-video .jw-controlbar {
  opacity: 1 !important;
  visibility: visible !important;
}

/* bring the play/pause, mute/volume control buttons above the overlay on the video player */
.jw-display .jw-icon,
.jw-controlbar {
  z-index: 1;
}

.jw-slider-horizontal.jw-chapter-slider-time {
  margin: 0 12px;
  padding: 0 !important;
}

.jw-breakpoint-7:not(.jw-flag-audio-player) .jw-controlbar .jw-slider-time,
.jw-breakpoint-7:not(.jw-flag-audio-player)
  .jw-controlbar
  .jw-slider-horizontal.jw-chapter-slider-time {
  margin: 0 60px;
  padding: 0 !important;
}

/* controls for preview videos */
.preview-video .jw-icon.jw-icon-volume.jw-button-color.jw-reset {
  position: absolute;
  right: 10px;
  bottom: 40px;
  z-index: 20;
}

@media (min-width: 767px) {
  .preview-video .jw-icon.jw-icon-volume.jw-button-color.jw-reset {
    right: 50px;
    bottom: 164px;
  }
}

.preview-video .jw-svg-icon,
.preview-video .jw-icon {
  width: 30px !important;
  height: 30px !important;
  line-height: 1 !important;
}

/* remove outline that displays on hover */
.preview-video .jw-icon-volume::after {
  opacity: 0 !important;
}

/* fix ads time slider positioning */
.jwplayer.jw-flag-ads .jw-controlbar {
  flex-direction: row !important;
}

.jwplayer.jw-flag-ads .jw-controlbar .jw-slider-time {
  order: 2;
  margin: 0 60px 20px !important;
}
