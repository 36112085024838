:root {
  --outside-header-height: 60px;
  --site-header-height: 52px;
  --page-title-height: 64px;
  --combined-header-height-with-page-title: calc(
    var(--outside-header-height) + var(--site-header-height) +
      var(--page-title-height)
  );

  --pills-container-height: 99px;
}

@media (min-width: 767px) {
  :root {
    --outside-header-height: 68px;
    --site-header-height: 76px;
  }
}

body {
  @apply bg-network-black w-full;
}

main {
  min-height: calc(
    100dvh - var(--outside-header-height) - var(--site-header-height)
  );
}

p {
  font-family: inherit;
}

ul,
ul li {
  @apply list-none;
}

@import './fonts.css';
@import './player.css';
@import './gradients.css';
@import './piano.css';
@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import './calendar.css';

out-header {
  @apply z-[21];
}

/* header Scout App link */

#scout-star {
  transform-origin: center;
  animation: star-animation 5s forwards;
}

@keyframes star-animation {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(1);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.25);
  }
}

/* watch-regwall */
/* Watch RegWall */

.tp-modal-open .tp-modal {
  width: 100%;
  top: auto;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.4);
  max-height: 100vh;
  overflow-y: auto !important;
}

.tp-modal-open .tp-iframe-wrapper.tp-active {
  width: 100% !important;
  height: auto !important;
  background-color: #222;
  margin: 0;
  display: flex;
  justify-items: center;
  align-items: center;
}

.tp-modal-open .tp-iframe-wrapper.tp-active iframe {
  margin: 0 auto;
}

.tp-modal-hidden.tp-modal-open {
  overflow: visible !important;
}
.tp-modal-hidden .tp-modal,
.tp-modal-hidden .tp-backdrop {
  display: none !important;
}
