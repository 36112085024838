:root {
  --time-header-gutter-width: 70px;
}

/* EPG Calendar */
#epgCalendar .rbc-calendar {
  overflow: auto;
  max-height: calc(100% - 108px);
  display: block;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#epgCalendar .rbc-calendar::-webkit-scrollbar {
  display: none;
}

#epgCalendar .rbc-time-view {
  border: none;
  padding-right: 16px;
}

#epgCalendar .rbc-time-header-content {
  border-left: none;
}

#epgCalendar .rbc-header {
  border-bottom: none;
  pointer-events: none;
}

#epgCalendar .rbc-header + .rbc-header {
  border-left: none;
  margin-left: 4px;
}

#epgCalendar .rbc-time-header {
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #222222;
}

#epgCalendar .rbc-time-header.rbc-overflowing {
  border-right: none;
}

#epgCalendar .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: #222222;
}

#epgCalendar .rbc-allday-cell {
  display: none;
}

#epgCalendar .rbc-time-content {
  border-top: none;
  height: fit-content;
  overflow-y: unset;
}

#epgCalendar .rbc-time-gutter {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #222222;
  min-width: var(--time-header-gutter-width);
}

#epgCalendar .rbc-timeslot-group {
  border-left: none;
  border-bottom: none;
}

#epgCalendar .rbc-timeslot-group:not(:first-child) {
  margin-top: 4px;
}

#epgCalendar .rbc-day-slot .rbc-time-slot {
  border-top: none;
}

#epgCalendar .rbc-current-time-indicator {
  display: none;
}

#epgCalendar .rbc-events-container {
  border-left: none;
}

#epgCalendar .rbc-day-slot {
  background-color: transparent;
}

#epgCalendar .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

#epgCalendar .rbc-day-slot .rbc-event-content {
  line-height: 1.5;
}

#epgCalendar .rbc-today {
  background-color: transparent;
}

#epgCalendar .rbc-event {
  padding: 0;
  background-color: transparent;
}

#epgCalendar .rbc-day-slot + .rbc-day-slot {
  margin-left: 4px;
}

#epgCalendar .rbc-day-slot .rbc-event {
  border: none;
}

#epgCalendar .rbc-day-slot .rbc-event:not(:last-child) {
  padding-bottom: 4px;
}

#epgCalendar .rbc-event-label {
  display: none;
}

/* This is so the calendar with any number of days from 1 to 7 looks the same way */
#epgCalendar .range-1 .rbc-time-view {
  width: calc(110% - var(--time-header-gutter-width));
}

#epgCalendar .range-2 .rbc-time-view {
  width: calc(188% - var(--time-header-gutter-width));
}

#epgCalendar .range-3 .rbc-time-view {
  width: calc(271% - var(--time-header-gutter-width));
}

#epgCalendar .range-4 .rbc-time-view {
  width: calc(354% - var(--time-header-gutter-width));
}

#epgCalendar .range-5 .rbc-time-view {
  width: calc(437% - var(--time-header-gutter-width));
}

#epgCalendar .range-6 .rbc-time-view {
  width: calc(520% - var(--time-header-gutter-width));
}

#epgCalendar .range-7 .rbc-time-view {
  width: calc(603% - var(--time-header-gutter-width));
}

@media (min-width: 767px) {
  #epgCalendar .range-1 .rbc-time-view {
    width: calc(105% - var(--time-header-gutter-width));
  }

  #epgCalendar .range-2 .rbc-time-view {
    width: calc(105% - var(--time-header-gutter-width));
  }

  #epgCalendar .range-3 .rbc-time-view {
    width: calc(105% - var(--time-header-gutter-width));
  }

  #epgCalendar .range-4 .rbc-time-view {
    width: calc(131% - var(--time-header-gutter-width));
  }

  #epgCalendar .range-5 .rbc-time-view {
    width: calc(161% - var(--time-header-gutter-width));
  }

  #epgCalendar .range-6 .rbc-time-view {
    width: calc(191% - var(--time-header-gutter-width));
  }

  #epgCalendar .range-7 .rbc-time-view {
    width: calc(221% - var(--time-header-gutter-width));
  }
}
