/* FF real headline pro font */
@import url('https://use.typekit.net/pgr3rjh.css');

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public//fonts/PlusJakartaSans-ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public//fonts/PlusJakartaSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public//fonts/PlusJakartaSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public//fonts/PlusJakartaSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public//fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  letter-spacing: 0.03em;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public//fonts/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  letter-spacing: 0.03em;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public//fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  letter-spacing: 0.03em;
}
